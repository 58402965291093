.home {
  padding-top: 11rem;
  box-sizing: border-box;
  position: relative;
}

.home>nav {
  padding: 0 5vw;
  background-color: #8C87FF;
  width: 100%;
  padding-top: 3.5rem;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 5;
}

.home>nav img {
  height: 6.5rem;
  width: 9.5rem;
}

.home .container1 {
  width: 38.75rem;
  max-width: 90vw;
  margin: 0 auto;

}

.home .container2 {
  width: 49rem;
  max-width: 90vw;
  margin: 0 auto;

}

.home h1 {
  font-size: 6.75rem;
  color: #008afc;
  text-align: center;
  margin: 0;
  font-weight: 900;
}

.home h2 {
  font-size: 1.625rem;
  color: #008afc;
  text-align: center;
}

.home h3 {
  font-size: 1.3rem;
  color: #8c87ff;
  text-align: center;
  margin: 0.5rem 0;
}

.home svg {
  height: 1.25rem;
  cursor: pointer;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2rem;
}

.home_text {
  font-size: 0.9rem;
  color: #414141;
  margin-bottom: 4rem;
  text-align: center;
}

.home_text span {
  text-decoration: underline;
}

.home_imgsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;
}

.home_imgsContainer img {
  width: 100%;

}

.home_video {
  display: flex;
  justify-content: center;
}

.home_video video {
  max-height: 90vh;
  max-width: 90vw;
}

.home_subHeading {
  font-size: 2.3rem;
  color: #8c87ff;
  text-align: center;
  margin: 0.5rem 0;
  margin-bottom: 3rem;
}

.home_areas {
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.downloadHeading {
  padding-top: 4rem;
}

.home_areas>div {
  display: flex;
  flex-direction: column;

}

.home_areas span {
  font-size: 1.625rem;
  color: #414141;
  margin-bottom: 1rem;
}

.home_download {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 50vh;
}

.home_download img {
  height: 3.5rem;
  cursor: pointer;
}

.home_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
  background-color: #8C87FF;
  box-sizing: border-box;
}

.home_footer>h4 {
  font-size: 2.4rem;
  color: #fff;
  margin-bottom: 2rem;
  text-align: center;
}

.home_footer>img {
  height: 13.75rem;
}

.bottom {
  height: 6rem;
  width: 100%;
  background-color: #0000a0;
}

@media (max-width: 767px) {
  .home h1 {
    font-size: 5rem;
  }

  .home_areas {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .home_areas>div {
    width: 100%;
    text-align: center;
  }

  .home_download {
    margin-bottom: 25vh;
  }
}

@media (max-width: 576px) {
  .home_imgsContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}