@font-face {
  font-family: "lulo-clean";
  src: local("lulo-clean"),
    url(./assets/LuloCleanW01OneBold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "lulo-clean";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 100%;
}

@media (max-width: 1803px) {
  html {
    font-size: 94%;
  }
}

@media (max-width: 1686px) {
  html {
    font-size: 88%;
  }
}

@media (max-width: 1569px) {
  html {
    font-size: 82%;
  }
}

@media (max-width: 1452px) {
  html {
    font-size: 76%;
  }
}

@media (max-width: 1335px) {
  html {
    font-size: 70%;
  }
}

@media (max-width: 1280px) {
  html {
    font-size: 67%;
  }
}