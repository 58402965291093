.form {
  width: 31.25rem;
  max-width: 90vw;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 5rem;

}

.form h3 {
  font-size: 1.125rem;
  color: #0000a1;
  margin-bottom: 2rem;
}

.form_inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.form_inputContainer span {
  display: none;
}

.form_inputError label {
  color: red !important;
}


.form_inputError span {
  display: block;
  font-family: sans-serif;
  color: red;
  font-size: 0.8rem;
  margin-top: 3px;
}


.form_inputError input {
  border: 1px solid red !important;
  outline-color: red;
}

.form_inputContainer label {
  font-size: 0.9rem;
  color: #000;
  font-family: sans-serif;
  margin-bottom: 0.6rem;
}

.form_inputContainer input {
  height: 2.5rem;
  padding: 0 1rem;
}

.form button {
  height: 2.5rem;
  background-color: #0000a1;
  font-size: 0.9rem;
  color: #fff;
  border: none;
  width: 100%;
  cursor: pointer;
}

.form button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.optContainer {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.585);
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp {
  width: 750px;
  max-width: 90vw;
  background-color: #fff;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
  box-sizing: border-box;
}

.otp h3 {
  font-size: 1.5rem;
  color: #212121;
  margin-bottom: 3rem;
}

.otp_X {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
  margin-bottom: 2rem;
}

.otp input {
  height: 4rem;
  width: 4rem !important;
  margin: 0.25rem;
  font-size: 1.5rem;
}

.otp input {
  height: 4rem;
  width: 4rem !important;
  margin: 0.25rem;
  font-size: 1.5rem;
}

.otpError input {
  border: 2px solid red;
  outline-color: red;
}